<script setup lang="ts">
import type { MenuItem } from "~/models/Menu";

const { menu } = defineProps<{
  menu: MenuItem[];
}>();

const activeSubmenu = ref("");

function handleMouseEnter(submenuValue: string) {
  activeSubmenu.value = submenuValue;
}

function handleMouseLeave() {
  activeSubmenu.value = "";
}
</script>
<template>
  <nav role="navigation">
    <ul class="header-nav-list">
      <li
        v-for="menuItem in menu"
        :key="menuItem.label"
        class="header-nav-list-item"
        :class="{
          submenu: menuItem.submenu,
          [menuItem.style]: menuItem.style,
        }"
        @mouseenter="handleMouseEnter(menuItem.uid)"
        @mouseleave="handleMouseLeave"
      >
        <div class="header-nav-list-item-link" :data-submenu="menuItem.uid">
          <div class="level-1">
            <a
              v-if="!menuItem.submenu"
              :href="menuItem.path"
              :target="menuItem.external ? '_blank' : '_self'"
              :no-utm="!menuItem.external"
              class="header-nav-list-item-link"
            >
              <span>{{ menuItem.label }}</span>
            </a>
            <div v-else class="header-nav-list-item-container">
              <span>{{ menuItem.label }}</span>
              <i v-if="menuItem.submenu" class="extra-chevron"></i>
            </div>
          </div>
          <navSubMenuDesktop
            v-if="menuItem.submenu || menuItem.cta"
            :submenu="menuItem.submenu"
            :cta="menuItem.cta"
            :type="menuItem.type"
          />
          <dispatchUsersDesktop
            v-if="menuItem.dispatch"
            v-show="activeSubmenu === menuItem.uid"
            :dispatch="menuItem.dispatch"
          />
        </div>
      </li>
    </ul>
  </nav>
</template>
<style lang="scss" scoped>
.header-nav-list {
  display: flex;
  width: 100%;
  height: 100%;
  margin-left: $gutter * 4;
  @include mq(desktop) {
    justify-content: center;
    margin-left: $gutter * 2;
  }
  &-item {
    display: flex;
    flex-wrap: wrap;
    padding: 0 $gutter * 3;
    border-left: 1px solid $primary-color-400;
    width: 100%;
    @include mq(desktop) {
      width: auto;
      padding: 0 var(--showcase-size-200);
      text-align: center;
    }
    &.rounded {
      border: 0;
      .header-nav-list-item-link {
        span {
          background-color: #fff;
          padding: var(--showcase-size-050) var(--showcase-size-100);
          border-radius: 30px;
          font-size: 14px;
          font-weight: 600;
          text-transform: uppercase;
          color: var(--showcase-color-primary-200);
        }
      }
    }
    &.link {
      padding: 0;
      .header-nav-list-item-link {
        span {
          padding: var(--showcase-size-050) var(--showcase-size-100);
          border-radius: 30px;
          font-weight: 600;
          text-transform: uppercase;
          text-decoration: underline;
          font-size: 14px;
        }
      }
    }
    &-container {
      display: flex;
      align-items: center;
    }
    &-link {
      text-decoration: none;
      color: $white;
      display: flex;
      align-items: center;
      padding-left: $gutter * 3;
      justify-content: center;
      width: 100%;
      position: relative;
      @include mq(desktop) {
        padding-left: 0;
      }
      i {
        display: flex;
        margin-right: $gutter;
        font-size: 22px;
      }
      span {
        font-size: 16px;
      }
    }
    &.submenu {
      position: relative;
      &:hover {
        font-weight: 500;
        .submenu {
          display: block;
        }
        i {
          transform: rotate(180deg) translateX(-8px);
        }
      }
    }
  }
}
</style>
