<script setup lang="ts">
import type { MenuItem } from "~/models/Menu";

const props = defineProps<{
  menu: MenuItem[];
}>();

const activeItemId = ref(null);
const menuIsOpen = ref(false);
const firstLvlMenu = ref<any[]>([]);
const expandMenus = ref<any[]>([]);
const dispatchMenus = ref<any[]>([]);
const ctaDispatch = ref<any[]>([]);
const activeSubMenu = ref<string | null>(null);

const toggleActive = (uid: string) => {
  if (uid === activeItemId.value) {
    activeItemId.value = null;
  } else {
    activeItemId.value = uid;
  }
};
const closeMenu = ($event) => {
  $event.preventDefault();

  const targetUrl = new URL($event.currentTarget.href, window.location.origin).pathname;

  if (window.location.pathname === targetUrl) {
    menuIsOpen.value = false;
  }
};
const handleLinkClick = (menuItem: MenuItem, uid: string, event: Event) => {
  if (menuItem.submenu || menuItem.dispatch) {
    toggleActive(uid);
    setTimeout(() => {
      event.target.scrollIntoView({ behavior: "smooth", block: "start" });
    }, 200);
  }
};

const extractCtaDispatch = () => {
  props.menu.forEach((item) => {
    if (item.dispatch) {
      ctaDispatch.value.push(item);
    }
  });
};

const extractFirstLvlMenu = () => {
  props.menu.forEach((item) => {
    if (item.submenu || item.path) {
      firstLvlMenu.value.push(item);
    }
  });
};

const extractExpandMenu = () => {
  props.menu.forEach((item) => {
    item.submenu?.forEach((subItem) => {
      if (subItem.big_menu) {
        expandMenus.value.push({
          title: subItem.title,
          uid: subItem.uid,
          big_menu: subItem.big_menu,
          url: subItem.url,
          show_all: subItem.show_all,
        });
      }
    });
  });
};

const extractDispatchMenu = () => {
  props.menu.forEach((item) => {
    if (item.dispatch) {
      dispatchMenus.value.push({
        title: item.label,
        uid: item.uid,
        dispatch: item.dispatch,
      });
    }
  });
};

const toggleExpandMenu = (uid: string | null) => {
  activeSubMenu.value = activeSubMenu.value === uid ? null : uid;
};

const toggleNav = (event: Event, cta?: boolean) => {
  const target = event.target as HTMLInputElement;
  if (target.checked || cta) {
    document.body.classList.add("menu-open");
  } else {
    document.body.classList.remove("menu-open");
  }
};

onMounted(() => {
  extractFirstLvlMenu();
  extractExpandMenu();
  extractCtaDispatch();
  extractDispatchMenu();
});
</script>

<template>
  <nav role="navigation">
    <div class="nav-mobile">
      <input v-model="menuIsOpen" type="checkbox" @change="toggleNav" />
      <span></span>
      <span></span>
      <span></span>
      <div id="menu-overlay" class="nav-mobile-overlay">
        <ul
          v-show="!activeSubMenu"
          class="menu nav-mobile-list"
          :class="!activeSubMenu ? 'visible' : 'hidden'"
        >
          <li
            v-for="menuItem in firstLvlMenu"
            :key="menuItem.path"
            class="item"
            :class="{
              open: menuItem.uid === activeItemId,
              dispatch: menuItem.dispatch,
            }"
          >
            <span
              v-if="menuItem.submenu"
              class="item-link"
              @click="handleLinkClick(menuItem, menuItem.uid, $event)"
            >
              <div class="level-1">
                <span>{{ menuItem.label }}</span>
                <i class="extra-chevron"></i>
              </div>

              <ul v-if="menuItem.submenu" class="level-2">
                <li v-for="submenuItem in menuItem.submenu" :key="submenuItem.label">
                  <NuxtLink
                    v-if="!submenuItem.big_menu"
                    :to="submenuItem.url"
                    :target="submenuItem.external ? '_blank' : '_self'"
                    @click="closeMenu($event)"
                  >
                    <span class="level-2-title" v-html="submenuItem.title" />
                    <span
                      v-if="submenuItem.subtitle"
                      class="level-2-subtitle"
                      v-html="submenuItem.subtitle"
                    />
                  </NuxtLink>
                  <div
                    v-else
                    :data-submenu="submenuItem.uid"
                    @click="toggleExpandMenu(submenuItem.uid)"
                  >
                    <span class="level-2-title" v-html="submenuItem.title" />
                    <span
                      v-if="submenuItem.subtitle"
                      class="level-2-subtitle"
                      v-html="submenuItem.subtitle"
                    />
                  </div>
                </li>
              </ul>
            </span>
            <a v-else :href="menuItem.path" class="item-link">
              <div class="level-1">
                <span>{{ menuItem.label }}</span>
              </div>
            </a>
          </li>
        </ul>

        <template v-for="menu in expandMenus" :key="menu.uid">
          <div
            v-show="menu.uid === activeSubMenu"
            class="expand-menu"
            :data-submenu="menu.uid"
          >
            <NavSubMenuMobile
              :menu="menu"
              :title="menu.title"
              @close="toggleExpandMenu()"
            />
          </div>
        </template>
        <template v-for="dispatchMenu in dispatchMenus" :key="dispatchMenu.title">
          <div v-show="dispatchMenu.uid === activeSubMenu" class="dispatch">
            <dispatchUsersMobile
              :dispatch="dispatchMenu.dispatch"
              :title="dispatchMenu.title"
              @close="toggleExpandMenu()"
            />
          </div>
        </template>

        <ul class="menu cta">
          <li
            v-for="menuItem in dispatchMenus"
            :key="menuItem.path"
            class="item"
            :class="{
              open: menuItem.uid === activeItemId,
              dispatch: menuItem.dispatch,
            }"
          >
            <span
              class="item-link"
              @click="handleLinkClick(menuItem, menuItem.uid, $event)"
            >
              <div class="level-1">
                <span>{{ menuItem.title }}</span>
                <i class="extra-arrow-rounded"></i>
              </div>

              <ul v-if="menuItem.dispatch" class="level-2">
                <li
                  v-for="submenuItem in menuItem.dispatch.cta"
                  :key="submenuItem.label"
                  :class="submenuItem.type"
                >
                  <NuxtLink
                    :to="submenuItem.url"
                    :target="submenuItem.external ? '_blank' : '_self'"
                    :no-utm="!submenuItem.external"
                  >
                    <span class="level-2-title" v-html="submenuItem.label" />
                    <i class="extra-arrow-regular"></i>
                  </NuxtLink>
                </li>
              </ul>
            </span>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>
<style lang="scss" scoped>
.nav-mobile {
  display: flex;
  flex-direction: column;
  position: relative;
  top: 25px;
  right: 0;
  z-index: 1;
  user-select: none;
  input {
    display: flex;
    width: 40px;
    height: 32px;
    position: absolute;
    cursor: pointer;
    opacity: 0;
    z-index: 2;
  }
  span {
    display: flex;
    width: 29px;
    height: 2px;
    margin-bottom: 5px;
    position: relative;
    background: #ffffff;
    border-radius: 3px;
    z-index: 1;
    transform-origin: 5px 0px;
    transition:
      transform 0.2s cubic-bezier(0.77, 0.2, 0.05, 1),
      background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
      opacity 0.55s ease;
    &:first-child {
      transform-origin: 0% 0%;
    }
    &:nth-last-child(2) {
      transform-origin: 0% 100%;
    }
  }
  #menu-overlay {
    background-color: $white;
    width: 100vw;
    height: 100vh;
    position: absolute;
    margin: -50px 0 0 -50px;
    -webkit-font-smoothing: antialiased;
    transform-origin: 0% 0%;
    top: 82px;
    right: -16px;
    transform: translate3d(110vw, 0, 0);
    transition: transform 0.2s cubic-bezier(0.77, 0.2, 0.05, 1);
    .menu {
      display: flex;
      flex-wrap: wrap;
      padding: 0 var(--showcase-size-100) var(--showcase-size-175);
      &.cta {
        flex-direction: column-reverse;
        margin-bottom: var(--showcase-size-200);
      }
      .item {
        padding: 0;
        width: 100%;
        border-top: 1px solid rgba(0, 0, 0, 0.19);
        &:first-child {
          border: 0;
        }
        .item-link {
          display: flex;
          align-items: center;
          color: $dark;
          text-decoration: none;
          font-family: "Poppins";
          font-weight: 500;
          width: 100%;
          height: fit-content;
          margin-bottom: 0;
          flex-wrap: wrap;
          font-size: 18px;
          i {
            font-size: 22px;
            width: 24px;
            height: 24px;
            min-width: 24px;
            justify-content: center;
            display: flex;
          }
          span {
            border: 0;
            background: none;
            width: 100%;
            height: 100%;
            margin-left: 14px;
            font-size: 15px;
            margin-bottom: 0;
            padding: $gutter * 3 0 $gutter * 3 var(--showcase-size-100);
          }

          .level-2 {
            display: none;
            max-height: 0;
            overflow: hidden;
            transition: all ease-in-out 0.2s;
            width: 100%;
            padding: 0;
            background-color: $white;
            &-title {
              font-weight: 600;
              padding-top: 0;
              padding-bottom: var(--showcase-size-050);
              line-height: 24px;
              font-size: 16px;
            }
            &-subtitle {
              padding-top: 0;
              line-height: 22px;
              font-weight: 400;
              padding-bottom: var(--showcase-size-050);
              font-size: 14px;
            }
            li {
              width: 100%;
              border-bottom: 1px solid var(--showcase-color-grey-200);
              list-style: decimal;
              margin-bottom: var(--showcase-size-150);
              padding-bottom: var(--showcase-size-100);
              &:last-child {
                margin-bottom: 0;
              }
              a {
                text-decoration: none;
                color: var(--showcase-color-additional-dark);
              }
            }
          }

          .level-1 {
            display: flex;
            align-items: center;
            display: flex;
            justify-content: space-between;
            width: 100%;
            span {
              font-size: 18px;
            }
            i {
              margin-left: $gutter * 3;

              &[class*="extra-"] {
                align-items: center;
                justify-content: center;
                font-size: 12px;
                margin-right: var(--showcase-size-100);
              }

              & + span {
                padding-left: 0;
              }
            }
          }
        }
        &.open {
          border-bottom: 0;
          &.dispatch {
            .item-link {
              .level-1 {
                i {
                  transform: rotate(180deg) translateX(0);
                }
              }
            }
          }
          .item-link {
            border-bottom: 0;
            .level-1 {
              font-weight: 800;
              i {
                transform: rotate(180deg) translateX(-8px);
              }
            }
            ul {
              max-height: 500px;
              transition: all ease-in-out 0.2s;
              display: block;
              li {
                list-style: none;
              }
            }
          }
        }
        &.dispatch {
          text-transform: uppercase;
          margin-bottom: var(--showcase-size-100);
          display: flex;
          align-items: center;
          border-top: 0;
          .item-link {
            .level-1 {
              padding: var(--showcase-size-050) var(--showcase-size-100);
              box-shadow: 0px 2px 4px 1px rgba(0, 0, 0, 0.25);
              border-radius: 4px;
              border: 1px solid #d9d9d9;
              i {
                margin-right: -5px;
              }
            }
            .level-2 {
              margin-top: var(--showcase-size-050);
              li {
                border: 0;
                margin: 0;
                padding: var(--showcase-size-050) var(--showcase-size-075)
                  var(--showcase-size-050) var(--showcase-size-100);
                &:first-child {
                  margin-bottom: var(--showcase-size-050);
                }
                a {
                  color: var(--showcase-color-additional-light);
                  display: flex;
                  align-items: center;
                  font-weight: 700;
                  .level-2-title {
                    font-weight: 600;
                  }
                  i {
                    font-size: 18px;
                    display: flex;
                    align-items: center;
                  }
                }

                &.candidat {
                  background-color: var(--showcase-color-primary-200);
                }
                &.recruteur {
                  background-color: var(--showcase-color-secondary-200);
                  border-radius: 0 0 3px 3px;
                }
              }
            }
            span {
              font-size: 14px;
              padding: 0;
              font-weight: 800;
            }
          }
        }
      }
    }
  }
  input:checked {
    & ~ span {
      opacity: 1;
      transform: rotate(45deg) translate3d(-3px, -1px, 0);
      background: #fff;
    }
    & ~ span:nth-last-child(3) {
      opacity: 0;
      transform: rotate(0deg) scale(0.2, 0.2);
    }
    & ~ span:nth-last-child(2) {
      transform: rotate(-45deg) translate3d(0, -1px, 0);
    }
    & ~ #menu-overlay {
      transform: none;
      overflow: auto;
      padding-bottom: var(--showcase-size-350);
    }
  }
}
.nav-mobile-list.visible ~ .menu-cta .menu-cta-text {
  display: none;
}
.menu-cta {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  flex-direction: column-reverse;
  margin-bottom: var(--showcase-size-250);
  color: red;

  .menu-cta-text {
    display: block;
    width: 100%;
    transform: none;
    text-align: center;
    color: var(--showcase-color-additional-dark);
  }

  &-text {
  }
  &-button {
    width: 100%;
    margin: var(--showcase-size-150) auto 0;
    justify-content: center;
    display: flex;
    span {
      display: flex;
      width: max-content;
      align-items: center;
      &.rounded {
        background-color: var(--showcase-color-primary-200);
        height: 100%;
        padding: var(--showcase-size-075) var(--showcase-size-150);
        border-radius: 30px;
        color: var(--showcase-color-additional-light);
        text-transform: uppercase;
        font-weight: 600;
      }
      &.link {
        color: var(--showcase-color-primary-200);
        text-decoration: underline;
        text-transform: uppercase;
        font-weight: 800;
        font-size: 15px;
      }
    }
  }
}
</style>
